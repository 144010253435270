export default {
  lang: "kr",
  title: "Flala",
  langText: "한국어",
  home: "홈",
  contact: "연락",
  joinus: "당사 가입",
  about: "당사 관련",
  join: "파트너 되기",

  // 充值
  pleaseCustom: "사용자 지정",
  recharge: "충전",
  rechargeAccount: "충전 계정",
  pleaseRechargeAccount: "사용자 계정 입력",
  recharge_list: "충전 메뉴 선택",
  nation: "국가 및 지역",
  payType: "지불 방식",
  pleasePayType: "지불 방식 선택",
  bankCode: "은행 비번",
  confirmPay: "지불 확인:",

  // 协议
  agrPrivacy: "개인 정보",
  agrUser: "사용자 협의",
  agrRecharge: "충전 협의",
  notUser: "사용자 없음！",

  // 导航
  nav_about: "Flala",
  nav_recharge: "충전 서비스",
  nav_news: "뉴스 센터",

  // 页脚
  footer_title1: "FLala관련",
  footer_title2: "당사 주목",
  footer_title3: "APP",
  footer_msg1: "간단한 채팅, 자유의 소통.",

  // 主页
  view1_title1: "간단한 채팅",
  view1_title2: "자유의 소통",
  view1_sub_title1:
    "Flala는 다중 언어 동기 통역을 제공하는 채팅 교제app이며 우리는 하나의 목표를 품고 출발합니다: 글로벌 사용자를 위하여 언제 어디서나 손쉽게 마음껏 채팅하고 심정을 공유하는 무한계 교제 공간을 구성합니다.",
  view1_sub_title2:
    "언어, 문자, 영상 등 다중 기능을 통하여 전세계의 사용자와 실시간 소통합니다. 이곳은 심령의 휴양지이며 Flala는 사용자를 조력하여 매번 소통에서 서로 치유하고 서로 에너지를 흡수하여 더욱 좋은 생활으로 나가게합니다.",
  view1_downloads: "다운로드량",
  view1_countries: "국가수",
  view1_video: "영상 접속율",

  view2_title1: "왜 선택하는가",
  view2_title2: "Flala 채팅 소프트웨어",
  view2_sub_title:
    "Flala가 구성한 무한계 교제 공간내에서, 즐거움, 흥분, 고독, 분노를 물론하고 당신은 원키로 정서를 석방하고 마음껏 채팅하며 전세계의 회답을 접수하고 최적의 청취자를 찾을 수 있습니다.",

  view3_title1: "뉴스 센터",
  view3_more: "더보기+",
  view3_sub_title: "Flala 최신 뉴스&행사",

  view4_title1: "사용자 소리",
  view4_sub_title: "Flala 에서 발생한 이야기",

  // 新闻
  news_title: "뉴스 센터",
  news_sub_title: "Flala 최신 뉴스&행사",

  // 代理商充值
  notAvailable: "This amount is not available for now.",
  toLogin: "Login",
  agencyAccount: "Please log in with agency account.",
  loginAccount: "Account",
  Password: "Password",
};
