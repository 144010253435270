import cn from "./cn.js";
import vi from "./vi.js";
import en from "./en.js";
import zh from "./tw.js";
import jp from "./jp.js"; // ja-JP
import ia from "./ia.js"; // in-ID
import ko from "./ko.js"; // ko-KR
import th from "./th.js"; // th-TH
import ms from "./ms.js";

export default {
  "zh-CN": cn,
  en,
  zh,
  vi,
  ms,
  "ja-JP": jp,
  "ko-KR": ko,
  "th-TH": th,
  "in-ID": ia,
};
