export default {
  lang: "TH",
  title: "Flala",
  langText: "ไทย",
  home: "หน้าแรก",
  contact: "ติดต่อ",
  joinus: "เข้าร่วมกับเรา",
  about: "เกี่ยวกับเรา",
  join: "กลายเป็นผู้เป็นหุ้นส่วน",

  // 充值
  pleaseCustom: "กำหนดเอง",
  recharge: "เติมเงิน",
  rechargeAccount: "บัญชีเติมเงิน",
  pleaseRechargeAccount: "ป้อนบัญชีผู้ใช้ของคุณ",
  recharge_list: "เลือกแพ็กเกจเติมเงิน",
  nation: "ประเทศและภูมิภาค",
  payType: "วิธีการชำระเงิน",
  pleasePayType: "เลือกวิธีการชำระเงิน",
  bankCode: "รหัสผ่านของบัญชีธนาคาร",
  confirmPay: "ยืนยันการชำระเงิน:",

  // 协议
  agrPrivacy: "นโยบายความเป็นส่วนตัว",
  agrUser: "ข้อตกลงผู้ใช้",
  agrRecharge: "ข้อตกลงการเติมเงิน",
  notUser: "ไม่มีผู้ใช้！",

  // 导航
  nav_about: "Flala",
  nav_recharge: "บริการเติมเงิน",
  nav_news: "ศูนย์ข่าวสาร",

  // 页脚
  footer_title1: "เกี่ยวกับ FLala",
  footer_title2: "ติดตามเรา",
  footer_title3: "APP",
  footer_msg1: "แชทง่ายขึ้น สื่อสารสะดวกมากขึ้น",

  // 首页
  view1_title1: "แชทง่ายขึ้น",
  view1_title2: "สื่อสารสะดวกมากขึ้น",
  view1_sub_title1:
    "Flala เป็น app โซเชียลที่ให้การแปลทันทีในหลายภาษา เรามีเป้าหมายเดียวคือสร้างพื้นที่โซเชียลที่ไร้ขอบเขตสำหรับผู้ใช้ทั่วโลกเพื่อแชทและแบ่งปันอารมณ์ได้อย่างง่ายดายทุกที่ทุกเวลา",
  view1_sub_title2:
    "สื่อสารกับผู้ใช้ทั่วโลกแบบเรียลไทม์ผ่านฟังก์ชันที่หลากหลาย เช่น เสียง ข้อความ และวิดีโอ นี่คือสถานที่เยียวยาจิตใจ Flala ช่วยให้ผู้ใช้เยียวยาซึ่งกันและกัน ดึงพลังงานจากกันและกันในทุกการสื่อสาร และก้าวสู่ชีวิตที่ดียิ่งขึ้น",
  view1_downloads: "ยอดดาวน์โหลด",
  view1_countries: "จำนวนประเทศ",
  view1_video: "อัตราการเข้าถึงวิดีโอ",

  view2_title1: "ทำไมต้องเลือก",
  view2_title2: "ซอฟต์แวร์แชท Flala",
  view2_sub_title:
    "ในพื้นที่โซเชียลอันไร้ขอบเขตที่สร้างขึ้นโดย Flala ไม่ว่าคุณจะมีความสุข ตื่นเต้น เหงา หรือโกรธ คุณสามารถปลดปล่อยอารมณ์ของคุณได้ด้วยคลิกเดียว แชทและรับการตอบกลับจากทั่วโลก และค้นหาผู้รับฟังที่ดีที่สุดของคุณ",

  view3_title1: "ศูนย์ข่าวสาร",
  view3_more: "เพิ่มเติม+",
  view3_sub_title: "ข่าวสาร & กิจกรรมล่าสุดของ Flala",

  view4_title1: "เสียงของผู้ใช้",
  view4_sub_title: "เรื่องราวที่เกิดขึ้นบน Flala",

  // 新闻
  news_title: "ศูนย์ข่าวสาร",
  news_sub_title: "ข่าวสาร & กิจกรรมล่าสุดของ Flala",

  // 代理商充值
  notAvailable: "จำนวนเงินปัจจุบันไม่รองรับการชำระเงิน",
  toLogin: "เข้าสู่ระบบ",
  agencyAccount: "โปรดล็อกอินบัญชีของตัวแทน",
  loginAccount: "บัญชี",
  Password: "รหัสผ่าน",
};
