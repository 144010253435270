import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./lang";

Vue.use(VueI18n);

let localeLan = localStorage.getItem("userLanguage") || "en";

const i18n = new VueI18n({
  locale: localeLan,
  messages,
  silentTranslationWarn: true,
});

export default i18n;

export const langList = [
  {
    label: "中文简体",
    value: "zh-CN",
  },
  {
    label: "中文繁體",
    value: "zh",
  },
  {
    label: "English",
    value: "en",
  },
  {
    label: "Tiếng Việt",
    value: "vi",
  },
  {
    label: "Indonesia",
    value: "in-ID",
  },
  {
    label: "ภาษาไทย",
    value: "th-TH",
  },
  //   {
  //     label: "일본어",
  //     value: "ko-KR",
  //   },
  //   {
  //     label: "日本語",
  //     value: "ja-JP",
  //   },
  //   {
  //     label: "Melayu",
  //     value: "ms",
  //   },
];
