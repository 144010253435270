export default {
  lang: "In",
  title: "Flala",
  langText: "Bahasa Indonesia",
  home: "Halaman pertama",
  contact: "Kontak",
  joinus: "Bergabung dengan kami",
  about: "Tentang kami",
  join: "Menjadi partner",

  // 充值
  pleaseCustom: "Definisi pengguna",
  recharge: "Isi ulang",
  rechargeAccount: "Akun isi ulang",
  pleaseRechargeAccount: "Masukkan akun pengguna anda",
  recharge_list: "Memilih paket isi ulang",
  nation: "Negara dan wilayah",
  payType: "Cara pembayaran",
  pleasePayType: "Memilih cara pembayaran",
  bankCode: "Sandi bank",
  confirmPay: "Konfirmasi pembayaran:",

  // 协议
  agrPrivacy: "Kebijakan privasi",
  agrUser: "Perjanjian pengguna",
  agrRecharge: "Perjanjian isi ulang",
  notUser: "Pengguna tidak ada.",

  // 导航
  nav_about: "Flala",
  nav_recharge: "Layanan isi ulang",
  nav_news: "Pusat berita",

  // 页脚
  footer_title1: "Tentang Flala",
  footer_title2: "Memperhatikan kami",
  footer_title3: "APP",
  footer_msg1: "Obrolan menjadi mudah, komunikasi lebih bebas.",

  // 首页
  view1_title1: "Obrolan menjadi mudah",
  view1_title2: "Komunikasi lebih bebas",
  view1_sub_title1:
    "Flala adalah app sosial global yang menyediakan terjemahan instan multibahasa, kami berangkat dengan satu tujuan: Untuk membangun ruang sosial tanpa batas bagi pengguna di seluruh dunia untuk mengobrol dengan mudah, dan berbagi emosinya kapan saja, di mana saja.",
  view1_sub_title2:
    "Kami berkomunikasi dengan pengguna di seluruh dunia secara real time melalui berbagai fungsi seperti suara, teks dan video. Ini adalah tempat penyembuhan bagi hati, dari setiap komunikasi, Flala membantu pengguna menyembuhkan saling, dan menarik energi dari satu sama lain, untuk melanjutkan kehidupan yang lebih baik.",
  view1_downloads: "Kuantitas unduhan",
  view1_countries: "Jumlah negara",
  view1_video: "Laju akses video",

  view2_title1: "Mengapa memilih",
  view2_title2: "Perangkat lunak obrolan Flala",
  view2_sub_title:
    "Di ruang sosial tanpa batas yang dibangun oleh Flala, apakah senang, bersemangat, kesepian atau marah, anda dapat melepaskan emosi anda dengan satu tombol, mengobrol dengan bebas dan menerima tanggapan dari seluruh dunia, dan menemukan pendengar terbaik anda.",

  view3_title1: "Pusat berita",
  view3_more: "Lebih banyak +",
  view3_sub_title: "Berita & acara terbaru Flala",

  view4_title1: "Suara pengguna",
  view4_sub_title: "Cerita terjadi di Flala",

  // 新闻
  news_title: "Pusat berita",
  news_sub_title: "Berita & acara terbaru Flala",

  // 代理商充值
  notAvailable: "Jumlah ini tidak tersedia untuk saat ini.",
  toLogin: "masuk",
  agencyAccount: "Silakan masuk dengan akun agensi",
  loginAccount: "Akun",
  Password: "Kata Sandi",
};
