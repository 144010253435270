export default {
  lang: "en",
  title: "Flala",
  langText: "英語",
  home: "首頁",
  contact: "聯系",
  joinus: "加入我們",
  about: "關於我們",
  join: "成為合伙人",

  // 充值
  pleaseCustom: "自定義",
  recharge: "充值",
  rechargeAccount: "充值帳號",
  pleaseRechargeAccount: "輸入您的用戶帳號",
  recharge_list: "選擇充值套餐",
  nation: "國家和地區",
  payType: "付款方式",
  pleasePayType: "選擇付款方式",
  bankCode: "銀行密碼",
  confirmPay: "確認付款:",

  // 协议
  agrPrivacy: "隱私政策",
  agrUser: "使用者協定",
  agrRecharge: "充值協定",
  notUser: "用戶不存在！",

  // 导航
  nav_about: "Flala",
  nav_recharge: "充值服務",
  nav_news: "新聞中心",

  // 页脚
  footer_title1: "關於 FLala",
  footer_title2: "關注我們",
  footer_title3: "APP",
  footer_msg1: "聊天變簡單，溝通更自由。",

  // 首页
  view1_title1: "聊天變簡單，",
  view1_title2: "溝通更自由。",
  view1_sub_title1:
    "Flala是一款提供多語言即時翻譯的聊天社交app，我們懷著一個目標出發：為全球用戶構建一個可以隨時隨地輕鬆暢聊、分享心情的無界社交空間。",
  view1_sub_title2:
    "通過語音、文字、視頻等多重功能與全世界的用戶實時溝通。這裡是心靈的療養之地，Flala助力用戶從每一次的溝通中相互治愈、相互汲取能量，奔赴更好的生活。",
  view1_downloads: "下載量",
  view1_countries: "國家數",
  view1_video: "視頻接入率",

  view2_title1: "為什麼選擇",
  view2_title2: "Flala 聊天軟體",
  view2_sub_title:
    "在 Flala 所構建的無界社交空間內，不論快樂、興奮，還是孤獨、憤怒，你都可以一鍵釋放情緒，暢聊並接收來自全世界的回應，找到你的最佳傾聽者。",

  view3_title1: "新聞中心",
  view3_more: "更多e+",
  view3_sub_title: "Flala 最新消息&活動",

  view4_title1: "用戶聲音",
  view4_sub_title: "發生在 Flala 上的故事",

  // 新闻
  news_title: "新聞中心",
  news_sub_title: "Flala 最新消息&活動",

  // 代理商充值
  notAvailable: "當前金額不支持支付",
  toLogin: "登錄",
  agencyAccount: "請登錄代理人賬號",
  loginAccount: "賬號",
  Password: "密碼",
};
