export default {
  lang: "en",
  title: "Flala",
  langText: "英语",
  home: "首页",
  contact: "联系",
  joinus: "加入我们",
  about: "关于我们",
  join: "成为合伙人",
  copyDone: "复制成功",
  copyError: "复制失败",

  // 成为合伙人
  contactTitle: "你希望我们用什么方式联系你?",
  WhatsApp: "请输入您的WhatsApp号码",
  Telegram: "请输入您的Telegram号码",
  Mailbox: "请输入您的邮箱账号",
  contactYou: "我们会在1个工作日内联系您",
  submitBtn: "提交信息",
  pleaseInput: "请输入联系方式",
  Submitted: "提交成功!",
  ContactUs: "联系我们",
  Email: "邮箱",
  PhoneNumber: "联系号码",
  Account: "账号",

  // 充值
  pleaseCustom: "自定义",
  recharge: "充值",
  rechargeAccount: "充值账户",
  pleaseRechargeAccount: "输入您的用户账号",
  recharge_list: "选择充值套餐",
  nation: "国家和地区",
  payType: "付款方式",
  pleasePayType: "选择付款方式",
  bankCode: "银行密码",
  confirmPay: "确认付款:",

  // 协议
  agrPrivacy: "隐私政策",
  agrUser: "用户协议",
  agrRecharge: "充值协议",
  notUser: "用户不存在！",

  // 导航
  nav_about: "Flala",
  nav_recharge: "充值服务",
  nav_news: "新闻中心",

  // 页脚
  footer_title1: "关于 FLala",
  footer_title2: "关注我们",
  footer_title3: "APP",
  footer_msg1: "聊天变简单，沟通更自由。",

  // 首页
  view1_title1: "聊天变简单",
  view1_title2: "沟通更自由",
  view1_sub_title1:
    "Flala是一款提供多语言即时翻译的聊天社交app，我们怀着一个目标出发：为全球用户构建一个可以随时随地轻松畅聊、分享心情的无界社交空间。",
  view1_sub_title2:
    "通过语音、文字、视频等多重功能与全世界的用户实时沟通。这里是心灵的疗养之地，Flala助力用户从每一次的沟通中相互治愈、相互汲取能量，奔赴更好的生活。",
  view1_downloads: "下载量",
  view1_countries: "国家数",
  view1_video: "视频接入率",

  view2_title1: "为什么选择",
  view2_title2: "Flala 聊天软件",
  view2_sub_title:
    "在 Flala 所构建的无界社交空间内，不论快乐、兴奋，还是孤独、愤怒，你都可以一键释放情绪，畅聊并接收来自全世界的回应，找到你的最佳倾听者。",

  view3_title1: "新闻中心",
  view3_more: "更多+",
  view3_sub_title: "Flala 最新消息&活动",

  view4_title1: "用户声音",
  view4_sub_title: "发生在 Flala 上的故事",

  // 新闻
  news_title: "新闻中心",
  news_sub_title: "Flala 最新消息&活动",

  // 代理商充值
  notAvailable: "当前金额不支持支付",
  toLogin: "登录",
  agencyAccount: "请登录代理人账号",
  loginAccount: "账号",
  Password: "密码",
};
