export default {
  lang: "ms",
  title: "Flala",
  langText: "Bahasa Melayu",
  home: "Utama",
  contact: "Hubungi",
  joinus: "Sertai kami",
  about: "Tentang kami",
  join: "Menjadi rakan kongsi",

  // 充值
  pleaseCustom: "Langganan",
  recharge: "Deposit",
  rechargeAccount: "Akaun deposit",
  pleaseRechargeAccount: "Masukkan akaun pengguna anda",
  recharge_list: "Pilih pakej deposit",
  nation: "Negara dan Wilayah",
  payType: "Kaedah pembayaran",
  pleasePayType: "Pilih kaedah pembayaran",
  bankCode: "Kata laluan bank",
  confirmPay: "Sahkan pembayaran:",

  // 协议
  agrPrivacy: "Dasar privasi",
  agrUser: "Perjanjian pengguna",
  agrRecharge: "Perjanjian deposit",
  notUser: "Pengguna tidak wujud!",

  // 导航
  nav_about: "Flala",
  nav_recharge: "Perkhidmatan deposit",
  nav_news: "Pusat berita",

  // 页脚
  footer_title1: "Tentang FLala",
  footer_title2: "Ikuti kami",
  footer_title3: "APP",
  footer_msg1: "Sembang menjadi lebih mudah dan komunikasi lebih bebas.",

  // 首页
  view1_title1: "", // Sembang menjadi lebih senang
  view1_title2: "Komunikasi menjadi lebih bebas",
  view1_sub_title1:
    "Flala adalah aplikasi sembang dan sosial yang menyediakan terjemahan segera dalam berbilang bahasa. Kami mempunyai satu matlamat kepada pengguna global: membina ruang sosial tanpa sempadan di mana mereka boleh bersembang dan berkongsi mood mereka dengan senang pada bila-bila masa, di mana-mana sahaja.",
  view1_sub_title2:
    "Berkomunikasi dengan pengguna di seluruh dunia dalam masa nyata melalui pelbagai fungsi seperti suara, teks dan video. Ini adalah tempat penyembuhan untuk jiwa. Flala membantu pengguna menyembuhkan satu sama lain, menarik tenaga antara satu sama lain dalam setiap komunikasi dan mempunyai kehidupan yang lebih baik.	",
  view1_downloads: "Muat turun",
  view1_countries: "Bilangan negara",
  view1_video: "Kadar akses video",

  view2_title1: "Kenapa membuat pilihan",
  view2_title2: "Flala aplikasi sembang",
  view2_sub_title:
    "Dalam ruang sosial tanpa sempadan yang dibina dalam Flala, anda boleh melepaskan emosi anda dengan satu klik, sembang secara bebas dan menerima balasan dari seluruh dunia untuk mencari pendengar terbaik tanpa mengira anda gembira, teruja, sunyi atau marah, .",

  view3_title1: "Pusat berita",
  view3_more: "Lagi+",
  view3_sub_title: "Berita & Acara Terkini Flala",

  view4_title1: "Suara Pengguna",
  view4_sub_title: "Cerita yang berlaku dalam Flala",

  // 新闻
  news_title: "Pusat berita",
  news_sub_title: "Berita & Acara Terkini Flala",

  // 代理商充值
  notAvailable: "This amount is not available for now.",
  toLogin: "Login",
  agencyAccount: "Please log in with agency account.",
  loginAccount: "Account",
  Password: "Password",
};
