import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 权限路由集合，通过拓展符导入路由模块合并
// 自动引入路由模块
const files = require.context("@/router/modules", false, /\.js$/);
const modules = [];
files.keys().forEach((key) => {
  modules.push(...files(key).default);
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: modules,
});

export default router;
