export default {
  lang: "JP",
  title: "Flala",
  langText: "日本語",
  home: "ホームページ",
  contact: "お問い合わせ",
  joinus: "我々の一員になり",
  about: "我々について",
  join: "パートナーになる",

  // 充值
  pleaseCustom: "カスタム",
  recharge: "リチャージ",
  rechargeAccount: "リチャージアカウント",
  pleaseRechargeAccount: "ユーザーアカウントを入力してください",
  recharge_list: "リチャージリストを選択してください",
  nation: "国と地域",
  payType: "支払方法",
  pleasePayType: "支払方法を選択してください",
  bankCode: "銀行パスワード",
  confirmPay: "支払を確定します:",

  // 协议
  agrPrivacy: "プライバシーポリシー",
  agrUser: "ユーザー利用規約",
  agrRecharge: "リチャージ規約",
  notUser: "ユーザーが存在しません！",

  // 导航
  nav_about: "Flala",
  nav_recharge: "リチャージサービス",
  nav_news: "ニュースセンター",

  // 页脚
  footer_title1: " FLalaについて",
  footer_title2: "フォローする",
  footer_title3: "APP",
  footer_msg1: "チャットがより簡単になり、コミュニケーションがより自由になり。",

  // 首页
  view1_title1: "チャットがより簡単になり",
  view1_title2: "コミュニケーションがより自由になり",
  view1_sub_title1:
    "Flalaは多言語リアルタイム翻訳を提供するグローバルなソーシャルアプリであり、世界中のユーザーがいつでもどこでも気軽にチャットし、気持ちを分かち合うことができる境界のないソーシャルスペースを構築することを目指しております。",
  view1_sub_title2:
    "Flalaは、音声、文字、ビデオ、およびさまざまな機能を通じて、世界中のユーザーとリアルタイムなコミュニケーションを実現します。ここは心の療養地であり、Flalaは、ユーザーが毎回のコミュニケーションから互いに癒し、互いにポジティブなエネルギーを与え、より良い生活に向かって進むのを助けます。",
  view1_downloads: "ダウンロード数",
  view1_countries: "国の数",
  view1_video: "ビデオアクセス率",

  view2_title1: "Flala チャットアプリを選択する理由は",
  view2_title2: "",
  view2_sub_title:
    "Flalaが構築した境界のないソーシャルスペースでは、喜びも興奮も孤独も怒りも、感情をワンクリックでリリースし、チャットして世界からの応答を受け、ベスト聞き手を見つけることができます。",

  view3_title1: "ニュースセンター",
  view3_more: "もっと見る",
  view3_sub_title: "Flala 最新ニュース&活動",

  view4_title1: "ユーザーの声",
  view4_sub_title: " Flala で起きた物語",

  // 新闻中心
  news_title: "ニュースセンター",
  news_sub_title: "Flala 最新ニュース&活動",

  // 代理商充值
  notAvailable: "This amount is not available for now.",
  toLogin: "Login",
  agencyAccount: "Please log in with agency account.",
  loginAccount: "Account",
  Password: "Password",
};
