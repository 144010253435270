export default {
  lang: "en",
  title: "Flala",
  langText: "English",
  home: "Home",
  contact: "contact",
  joinus: "Join us",
  about: "About us",
  join: "Be a partner",
  copyDone: "Copy Success",
  copyError: "Copy Failed",

  // 成为合伙人
  contactTitle: "How would you like us to contact you?",
  WhatsApp: "Please enter your WhatsApp number",
  Telegram: "Please enter your Telegram number",
  Mailbox: "Please enter your email address",
  contactYou: "We will contact you within 1 working day",
  submitBtn: "Submit",
  pleaseInput: "Please enter your contact information",
  Submitted: "Submitted!",
  ContactUs: "Contact us",
  Email: "Email",
  PhoneNumber: "Phone Number",
  Account: "Account",

  // 充值
  pleaseCustom: "custom",
  recharge: "Recharge",
  rechargeAccount: "Recharge account",
  pleaseRechargeAccount: "Enter your user ID",
  recharge_list: "Select recharge package",
  nation: "country and region",
  payType: "Payment method",
  pleasePayType: "Select payment method",
  bankCode: "Bank code",
  confirmPay: "Confirm payment:",

  // 协议
  agrPrivacy: "privacy policy",
  agrUser: "user agreement",
  agrRecharge: "recharge agreement",
  notUser: "user does not exist!",

  // 导航
  nav_about: "Flala",
  nav_recharge: "Recharge service",
  nav_news: "Press Room",

  // 页脚
  footer_title1: "About FLala",
  footer_title2: "Follow us",
  footer_title3: "APP",
  footer_msg1: "Make Chat Easy, Make New Friends.",

  // 首页
  view1_title1: "Make Chat Easy,",
  view1_title2: "Make New Friends.",
  view1_sub_title1:
    "Chat to your audience as freely as you like.  No language, cultural, national or regional limitations in Flala.",
  view1_sub_title2:
    "Communicating with people around the world through language, video, text in real time.An unbound socializing space to chat easily and freely,where can share moods at any time and place.",
  view1_downloads: "Downloads",
  view1_countries: "Countries",
  view1_video: "Video Access",

  view2_title1: "Why Choose a",
  view2_title2: "Chatting App Like Flala",
  view2_sub_title:
    "Here in the unbound socializing space constructed by Flala, you can unleash all your emotions, like joy and excitement, or loneliness and fury, through a free chat with your best audience and receive a response from the world.",

  view3_title1: "Press Room",
  view3_more: "More+",
  view3_sub_title: "Flala Latest News&Activities",

  view4_title1: "Users Voice",
  view4_sub_title: "Stories Happening on Flala",

  // 新闻
  news_title: "Press Room",
  news_sub_title: "Flala Latest News&Activities",

  // 代理商充值
  notAvailable: "This amount is not available for now.",
  toLogin: "Login",
  agencyAccount: "Please log in with agency account.",
  loginAccount: "Account",
  Password: "Password",
};
