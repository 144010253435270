export default {
  lang: "Vi",
  title: "Flala",
  langText: "Tiếng Việt",
  home: "Trang chủ",
  contact: "Liên hệ",
  joinus: "Thêm vào chúng tôi",
  about: "Về chúng tôi",
  join: "Trở thành đối tác",

  // 充值
  pleaseCustom: "Tùy chỉnh",
  recharge: "Nạp tiền",
  rechargeAccount: "Tài khoản nạp tiền",
  pleaseRechargeAccount: "Nhập tài khoản người dùng của bạn",
  recharge_list: "Chọn gói nạp tiền",
  nation: "Quốc gia và khu vực",
  payType: "Cách thanh toán",
  pleasePayType: "Chọn cách thanh toán",
  bankCode: "Mật khẩu ngân hàng",
  confirmPay: "Xác nhận thanh toán:",

  // 协议
  agrPrivacy: "Chính sách bảo mật",
  agrUser: "Thỏa thuận người dùng",
  agrRecharge: "Thỏa thuận nạp tiền",
  notUser: "Không có người dùng này!",

  // 导航
  nav_about: "Flala",
  nav_recharge: "Dịch vụ nạp tiền",
  nav_news: "Trung tâm tin tức",

  // 页脚
  footer_title1: "Về Flala",
  footer_title2: "Theo dõi chúng tôi",
  footer_title3: "APP",
  footer_msg1: "Chat đơn giản hơn, giao tiếp tự do hơn,",

  // 首页
  view1_title1: "Chat đơn giản hơn",
  view1_title2: "Giao tiếp tự do hơn",
  view1_sub_title1:
    "Flala là một app mạng xã hội và trò chuyện cung cấp khả năng dịch nhanh đa ngôn ngữ. Chúng tôi đặt ra một mục tiêu: xây dựng một không gian mạng xã hội không giới hạn để người dùng toàn cầu có thể dễ dàng trò chuyện và chia sẻ tâm trạng mọi lúc mọi nơi.",
  view1_sub_title2:
    "Giao tiếp với người dùng trên toàn thế giới trong thời gian thực thông qua nhiều chức năng như giọng nói, văn bản và video. Đây là nơi chữa lành tâm hồn, Flala hỗ trợ người dùng chữa lành vết thương cho nhau và lấy năng lượng từ nhau trong mọi giao tiếp để hướng đến một cuộc sống tốt đẹp hơn.",
  view1_downloads: "Lượng tải xuống",
  view1_countries: "Số lượng quốc gia",
  view1_video: "Tốc độ truy cập video",

  view2_title1: "Tại sao chọn",
  view2_title2: "Phần mềm chat Flala",
  view2_sub_title:
    "Trong không gian mạng xã hội không giới hạn do Flala xây dựng, cho dù bạn đang vui vẻ, phấn khởi, cô đơn hay bực mình, bạn đều có thể giải phóng cảm xúc của mình chỉ bằng một cú nhấp chuột, chat thoải mái và nhận phản hồi từ khắp nơi trên thế giới để tìm ra người nghe tốt nhất của bạn.",

  view3_title1: "Trung tâm tin tức",
  view3_more: "Nhiều hơn+",
  view3_sub_title: "Tin mới nhất & hoạt động Flala",

  view4_title1: "Âm thanh người dùng",
  view4_sub_title: "Những câu chuyện đã xảy ra trên Flala",

  // 新闻
  news_title: "Trung tâm tin tức",
  news_sub_title: "Tin tức & hoạt động mới nhất Flala",

  // 代理商充值
  notAvailable: "Số tiền hiện tại không hỗ trợ thanh toán",
  toLogin: "Đăng nhập",
  agencyAccount: "Vui lòng đăng nhập vào tài khoản đại lý",
  loginAccount: "Tài khoản",
  Password: "Mật khẩu",
};
