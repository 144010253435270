const contentRouter = [
  {
    path: "/mobile",
    name: "homeMobile",
    component: () =>
      import(
        /* webpackChunkName: "mobile" */ "@/views/Mobile/HomeView/index.vue"
      ),
  },
  {
    path: "/recharge/mobile",
    name: "rechargeMobile",
    component: () =>
      import(
        /* webpackChunkName: "mobile" */ "@/views/Mobile/RechargeView/index.vue"
      ),
  },
  {
    path: "/news/mobile",
    name: "newsMobile",
    component: () =>
      import(
        /* webpackChunkName: "mobile" */ "@/views/Mobile/NewsView/index.vue"
      ),
  },
  {
    path: "/newsDetail/mobile",
    name: "newsDetailMobile",
    component: () =>
      import(
        /* webpackChunkName: "mobile" */ "@/views/Mobile/NewsView/newsDetail.vue"
      ),
  },
  {
    path: "/joinus/mobile",
    name: "joinMobile",
    component: () =>
      import(/* webpackChunkName: "PC" */ "@/views/Mobile/JoinView/index.vue"),
  },
];

export default contentRouter;
