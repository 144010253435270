import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/locales";
import VueClipBoard from "vue-clipboard2";

VueClipBoard.config.autoSetContainer = true;
Vue.use(VueClipBoard);

Vue.config.productionTip = false;

Vue.prototype.$prot = process.env;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
