<template>
  <div id="app">
    <!-- <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition> -->
    <router-view />
    <div
      v-if="isMobile && routeName != 'joinMobile'"
      :class="[isMobile ? 'mobile-entry' : 'entry']"
      @click="goJoin"
    >
      Become a partner >>
    </div>
    <div
      :class="[isMobile ? 'mobile-service' : 'service']"
      @click="toUrl"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      routeName: "",
      serviceUrl: "https://1875399.s5.udesk.cn/im_client/?web_plugin_id=1329",
    };
  },
  watch: {
    $route() {
      let nowWidth = window.screen.width;
      let nowName = this.$route.name;
      this.routeName = nowName;
      let query = this.$route.query;
      this.replaceUrl(nowWidth, nowName, query);
    },
  },
  mounted() {
    window.onresize = () => {
      let width = document.body.clientWidth;
      let name = this.$route.name;
      let query = this.$route.query;
      this.replaceUrl(width, name, query);
    };
  },
  methods: {
    replaceUrl(width, name, query) {
      try {
        let newName = name.replace("Mobile", "");
        if (width < 768) {
          this.isMobile = true;
          newName = newName + "Mobile";
        } else {
          this.isMobile = false;
        }
        this.$router
          .push({
            name: newName,
            query,
          })
          .catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    goJoin() {
      this.$router.push("/joinus");
    },
    toUrl() {
      let aLabel = document.createElement("a");
      //设置链接
      aLabel.setAttribute("href", this.serviceUrl);
      //新窗口打开链接
      aLabel.setAttribute("target", "_blank");
      //设置标签ID
      aLabel.setAttribute("id", "reportpoint");
      // 防止反复添加
      if (document.getElementById("reportpoint")) {
        document.body.removeChild(document.getElementById("reportpoint"));
      }
      document.body.appendChild(aLabel);
      aLabel.click();
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  padding: 0;
  margin: 0;
  height: 100%;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.3s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

a {
  text-decoration: none;
}

.entry {
  position: fixed;
  right: 0;
  bottom: 9.0104vw;
  width: 4.84375vw;
  height: 2.5vw;
  box-shadow: 0 2px 0.625vw 0 rgba(110, 62, 255, 0.17);
  border-radius: 2.5vw 0 0 2.5vw;
  color: rgba(110, 62, 255, 1);
  font-size: 0.625vw;
  font-weight: 700;
  padding: 0 0.72916vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.service {
  position: fixed;
  left: 0.46875vw;
  bottom: 22.3645vw;
  width: 2.6041vw;
  height: 2.6041vw;
  border-radius: 50%;
  box-shadow: 0 2px 0.625vw 0 rgba(110, 62, 255, 0.17);
  background: url("@/assets/img/Mobile/service-icon.png") no-repeat center;
  background-size: cover;
}

.mobile-entry {
  position: fixed;
  right: 0;
  bottom: 173px;
  width: 93px;
  height: 48px;
  box-shadow: 0 2px 12px 0 rgba(110, 62, 255, 0.17);
  border-radius: 48px 0 0 48px;
  color: rgba(110, 62, 255, 1);
  font-size: 12px;
  font-weight: 700;
  padding: 0 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.mobile-service {
  position: fixed;
  right: 0;
  bottom: 104px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 12px 0 rgba(110, 62, 255, 0.17);
  background: url("@/assets/img/Mobile/service-icon.png") no-repeat center;
  background-size: cover;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
