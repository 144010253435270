const contentRouter = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "PC" */ "@/views/PC/HomeView/index.vue"),
  },
  {
    path: "/recharge",
    name: "recharge",
    component: () =>
      import(/* webpackChunkName: "PC" */ "@/views/PC/RechargeView/index.vue"),
  },
  {
    path: "/agency",
    name: "agency",
    component: () =>
      import(/* webpackChunkName: "PC" */ "@/views/PC/RechargeView/agency.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "PC" */ "@/views/PC/NewsView/index.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () =>
      import(/* webpackChunkName: "PC" */ "@/views/PC/NewsView/newsDetail.vue"),
  },
  {
    path: "/joinus",
    name: "join",
    component: () =>
      import(/* webpackChunkName: "PC" */ "@/views/PC/JoinView/index.vue"),
  },
];

export default contentRouter;
